* {
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;
}


$blue: #1B98E0;
$lighter-blue: lighten($blue, 5%);
$dark-grey: rgb(70,70,70);
$grey: rgb(110,110,110);
$med-grey: rgb(120,120,120);

$light-grey: #e9ecf0;

.fa-star {
	color: #DAA520;
}
.review-container {
	text-align: center;
	font-weight: 600;
}
 a {
	 color:$blue;
	 &:hover {
		 color: lighten($blue,3%) !important;
	 }
	 &:active {
		 color: darken($blue,2%) !important;
	 }
	 &.nav-link {
		 color: $med-grey !important;
		 &:hover {
			 color: lighten($med-grey,10%) !important;
		 }
		 &:active {
			 color: darken($med-grey,3%) !important;
		 }
	 }
 }
.nav-navbar .nav-link {
	letter-spacing: normal;
}
* {
	letter-spacing: normal;
}
.btn-sm {
	font-weight: 700;
	font-size:12px;
	letter-spacing: 1.2px;
}
.main-content p {
	font-weight: 400;
}

p.highlight {
	background: $light-grey;
	border-radius: 4px;
	color: $dark-grey;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	padding: 5px 10px;
}

.btn-secondary {
	color: $dark-grey !important;
	font-weight: 700;
	font-size:12px;
	letter-spacing: 1.2px;
	&:hover, &:active {
		color: $dark-grey !important;
	}
}
.btn-light {
	color: $dark-grey !important;

	&:hover, &:active {
		color: $dark-grey !important;
	}
}
.btn-success, .btn-primary {
    color: #fff;
    background-color: $blue;
    border-color: $blue;
		font-weight: 700;
		color:white !important;
		font-size:12px;
		letter-spacing: 1.2px;
		&:hover {
			background-color: lighten($blue,3%) !important;
	    border-color: $blue !important;
			box-shadow: none !important;
			color:white !important;
		}
		&:active {
			background-color: darken($blue,2%) !important;
	    border-color: $blue !important;
			box-shadow: none !important;
			color:white !important;
		}
}

#marketing {
	h1 {

		text-align: center;
		font-size: 40px;
		margin: 20px auto;
		display: block;
	}
}

#examples {
	.example {
		margin: 20px 0;
		img {
			margin: 15px 0;
			width: 100%;
		}
		a {
			color: $blue;
			&:hover {
				color: $lighter-blue;
				text-decoration: underline;
			}
		}
		h3 {
			font-size: 22px;
			font-weight: 700;
			margin: 10px 0;
			color: $blue;
			display: inline-block;
		}
		p {
			font-size: 16px;
			color: $dark-grey;
			line-height: 26px;
			a {
				color: $blue;
				text-decoration: underline;
				font-weight: 600;
				&:hover {
					color: $lighter-blue;
				}
			}
		}

	}
}

#examples-link {
	background-color: $blue;
	color: white;
	display: block;
	width: 500px;
	text-align: center;
	margin: auto;
	padding: 10px 20px;
	border-radius: 3px;
	font-weight: 600;
	margin: 50px auto;
	margin-top: -10px;
	&:hover {
		background-color: $lighter-blue;
		text-decoration: underline;
	}
}

footer {
	font-weight: 400;
}

.plans .plan p.feature, .plans .plan p.price {
	margin: 0;
}

body {
	background: #fefefe;
	color: $dark-grey;
	font-weight: 400;
	#pricing {
		padding: 80px 0;
		padding-bottom: 0;
		#plans {
			width: 700px;
			@media (max-width: 700px) {
				width: 100%;
			}
			margin: 10px auto;
			margin-bottom: 0;
			box-shadow: 0 -40px 40px -40px rgba(150, 150, 150, 0.1), 0 40px 40px -40px rgba(150, 150, 150, 0.1);
			min-height: 100px;
			#plan {
				display: inline-block;
				vertical-align: top;
				width:100%;
				@media (min-width: 768px) {
					max-width: 49.5%;
				}
				padding: 30px 15px;
				.line {
					width: 80%;
					margin: auto;
					border-top: 1px solid rgb(240,240,240);
				}
				.cta-btn {
					border: none;
					padding: 10px 20px;
					width: 170px;
					margin: 20px auto;
					font-size: 20px;
				}
				p {
					text-align: center;
					margin: 0;
					font-size: 15px;
					font-weight: 500;
					color: $grey;
					b {
						font-weight: 700;
					}
					&.name {
						font-size: 28px;
						margin: 10px 0;
						margin-top: 0;
						font-weight: 600;
					}
					&.price {
						margin: 0;
						color: $med-grey;
						.value {
							color: $blue;
							font-size: 24px;
							font-weight: 600;
						}
					}

					b {
						font-weight: 600;
					}
				}
			}
		}
	}
	h1 {

		font-size: 40px;
		font-weight: 700;
		color: $dark-grey;

		line-height: 1.3em;
		display: inline-block;
		vertical-align: top;
	}
	h2 {

		font-size: 36px;
		font-weight: 700;
		color: $dark-grey;
		line-height: 1.3em;
		text-align: center;
	}
	p.caption {
		font-size: 20px;
		text-align: center;
		color: $med-grey;
		font-weight: 400;
		line-height: 2em;
		margin: 10px 0;
		i {
			font-style: italic;
		}
		b {
			font-weight: 600;
			color: $grey;
		}
	}
	.width {
		width: 1000px;
		margin: auto;
		@media (max-width: 1000px) {
			width: 100% !important;
		}
	}
	.cta-btn {
		display: block;
		width: 300px;
		background: $lighter-blue;
		color: white;
		text-decoration: none;

		font-size: 22px;
		cursor: pointer;
		font-weight: 600;
		padding: 15px 40px;
		margin: 50px auto;
		text-align: center;
		border: none;
		border-radius: 5px;
		border-bottom: 5px solid $blue;
		&:hover {
			background: lighten($lighter-blue, 5%);
			//text-decoration: underline;
			color: white !important;

		}
		&:visited {
			color: white;
			text-decoration: none;
		}
	}
	#splash {
		.width {
			position: relative;
		}
		position: relative;
		min-height: 400px;
		padding: 40px 0;
		h1 {
			font-size: 38px;
			width: 40%;
		}
		.splash-image {
			width: 59%;
			height: 350px;
			background-size: 100%;
			background-repeat: no-repeat;
			display: inline-block;
		}
		.splash-image2 {
			width: 250px;
			height: 380px;
			background-size: 100%;
			background-repeat: no-repeat;
			position: absolute;
			top:20px;
			left: 380px;
		}

		p.caption {
			font-size: 20px;
			text-align: center;
			color: $med-grey;

			font-weight: 400;
			line-height: 2em;
			margin: 30px 0;
			b {
				font-weight: 600;
				color: $grey;
			}
		}
	}
	#features {
		padding: 80px 0;
		margin: 0 auto;
		.feature-col {
			width:100%;
			@media (min-width: 758px) {
				max-width: 32.9%;
			}
			display: inline-block;
			vertical-align: top;
			padding: 20px 20px;
			padding-top: 30px;
			.icon {
				font-size: 40px;
				text-align: center;
				padding-bottom: 20px;
				display: block;
			}
			h3 {
				text-transform: uppercase;
				font-weight: 700;
				font-size: 18px;
				text-align: center;
				margin-bottom: 20px;

			}
			p {
				margin: 10px 0;
				line-height: 1.8em;
				text-align: center;
				font-weight: 500;
				color: $med-grey;
				b {
					font-weight: 600;
				}
			}
			a {
				margin: 10px 0;
				line-height: 1.8em;
				display: block;
				text-align: center;
				b {
					font-weight: 600;
				}
				color: $lighter-blue;

			}
		}
	}
	.navbar {
		#logo {
			font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			font-size: 26px;
			padding: 5px 0;
			color: $dark-grey;
			float:left;
			font-weight: 400;
			i {
				color: $lighter-blue;
				font-size: 20px;
				padding: 5px;
				font-weight: 300;
			}
			b {
				font-weight: 600;
			}
		}
		#menu {
			float:right;
			a {
				font-size: 14px;
				padding: 10px 12px;
				color: $grey;
				font-weight: 500;
				font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
				text-decoration: none;
				display: inline-block;
				&.signup {
					margin-left: 10px;
					border-radius: 5px;
					padding: 10px 16px;
					background: $lighter-blue;
					color: white;
					font-weight: 600;
					&:hover {
						color: white;
						background: lighten($lighter-blue, 5%);
					}
				}
				&:hover {
					text-decoration: underline;
					color: $dark-grey;
				}
			}
		}
	}
	#signup-form {
		h1 {
			text-align: center;
			font-size: 28px;
			margin-top: 20px;
			margin-bottom: 5px;
			width: 100%;
		}
		#payment-info i {
			font-style: italic;
			font-size: 10px;
			padding: 3px;
			color: $med-grey;
			float: right;
			a {
				color: $grey;
				text-decoration: underline;
				&:hover {
					color: $med-grey;
				}
			}
		}
		.caption {
			font-size: 14px;
			margin-bottom: 5px;
			text-transform: uppercase;
			font-weight: 700;
			color: $grey;
			&.caption2 {
				font-size: 16px;
				margin-bottom: 50px;
				color: $grey;
				text-transform: none;
				font-weight: 400;
				text-align: left;
				line-height: 1.5em;
				b {
					font-weight: 700;
				}
			}
		}

		label {
			font-weight: 700;
			text-transform: uppercase;
			font-size: 12px;
		}
		width: 350px;
		margin:auto;
		#card-element {
			margin: 15px 0;
			margin-top: 5px;
		}
		button.cta-btn {
			width: 100%;
			font-size: 20px;
			margin: 20px 0;
			padding: 10px 0;
			&:focus {
				outline: none;
			}
		}
		.btn-link {
			color: $med-grey;
			font-size: 14px;
		}
		input {

			width: 100%;
			background-color: white;
			padding: 12px 12px;
			border-radius: 4px;
			border: 1px solid rgb(220,220,220);
			box-shadow: 0 1px 3px 0 #e6ebf1;
			-webkit-transition: box-shadow 150ms ease;
			transition: box-shadow 150ms ease;
			color: #32325d;
			font-family: "Helvetica Neue", Helvetica, sans-serif;
			-webkit-font-smoothing: antialiased;
			font-size: 16px;
			margin: 15px 0;
			margin-top: 5px;
			&.cta-btn {

			}
			&::placeholder {
				color: #bbbbc2;
			}
			&:focus {
				outline: none;
				box-shadow: 0 1px 3px 0 #cfd7df;
			}
			&[type="checkbox"] {
				border: none;
				box-shadow: none;
				width: auto;
			}
		}
	}
	#footer {
		color: $med-grey;

		margin: 30px auto;
		margin-top: 80px;
		font-size: 14px;
	}
}

.me-img {
	background: url('/me.png');
	width: 40px;
	height: 40px;
	float:left;
	margin: 15px;
	margin-bottom:70px;
	margin-left: 0;
	margin-top: 0;
	display:inline-block;
	text-align: top;
	background-size: 100%;
	border-radius: 50%;
	background-repeat: no-repeat;
}

#clear, .clear {
	clear:both;
}
